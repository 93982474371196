<template>
    <div>
        <van-nav-bar v-if="fromName=='addHouse'" title="添加潜房" fixed @click-left="leftReturn" left-arrow >
        </van-nav-bar>
        <van-nav-bar v-else title="修改潜房" fixed @click-left="leftReturn" left-arrow ></van-nav-bar>
        <div class="content">
            <!--            业主信息-->
            <div class="part">
                <span class="part-subtitle">业主信息</span>
                <div class="part-inputpart">
                    <div class="part-inputpart-row">
                        <div :class=" 0==ownerInfo.name.toString().trim().length ? 'content-none' : 'content-have'"></div>
                        <span class="part-inputpart-row-header">客户</span>
                        <span class="content-divide">|</span>
                        <input v-model="ownerInfo.name" placeholder="请输入客户姓名">
                    </div>
                    <div class="part-inputpart-row">
                        <span :class=" 0==ownerInfo.phone.toString().trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">联系电话</span>
                        <span class="content-divide">|</span>
                        <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11" v-model="ownerInfo.phone" placeholder="填写联系方式" @blur="checkPhone">
                    </div>
                    <div id="sourcePanel" class="part-inputpart-row" @click="isSourceShow = !isSourceShow">
                        <span :class=" Object.keys(houseInfo.source).length === 0 ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">来源</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext" :class="0 === Object.keys(houseInfo.source).length? '':'partInputpartRowNormaltext' ">{{houseInfo.source.dictionaryTitle ? houseInfo.source.dictionaryTitle : '请选择'}}</span>
                        <img class="part-inputpart-row-right" :class=" isSourceShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
<!--                    <div>-->
<!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isSourceShow">-->
<!--                            <div class="part-inputpart-dropDownList-option" :class="item==houseInfo.source ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in sourceArr" @click="selectSourceOption(item)">{{item.dictionaryTitle}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div id="recommenderPanel" class="part-inputpart-row" @click="clickRecommenderShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">推荐人</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext" :class="''==ownerInfo.recommender ? 'referrerDefaul' : 'partInputpartRowNormaltext' ">{{ownerInfo.recommender? ownerInfo.recommender : '(非必填)'}}</span>
                        <!--                        <span class="part-inputpart-row-right part-inputpart-row-right-rightArrow"></span>-->
                        <img class="part-inputpart-row-right part-inputpart-row-right-rightArrow" src="../../../assets/images/youjiantou.png">
                    </div>
                </div>
            </div>
            <div class="part-placeHolder"></div>
            <!--            地址信息-->
            <div class="part">
                <span class="part-subtitle">地址信息</span>
                <div class="part-inputpart" v-if="isAddressHave" >
                    <div id="communityPanel" class="part-inputpart-row" @click="houseTypeCode=='2'||resourceStatus=='3'||houseTypeCode=='1' ? '' : clickCommunityShow()">
                        <div :class=" ''!=addressInfo.community ? 'content-have' : 'content-none' "></div>
                        <span class="part-inputpart-row-header">所在小区</span>
                        <span class="content-divide">|</span>
                        <span  class="part-inputpart-row-graytext"
                               :class=" {partInputpartRowNormaltext:''!=addressInfo.community,partInputpartRowGraytext : this.houseTypeCode==2||this.resourceStatus==3||this.houseTypeCode==1}">
                            {{ ''==addressInfo.community ? '请选择' : addressInfo.community}}
                        </span>
                        <img class="part-inputpart-row-right part-inputpart-row-right-rightArrow" src="../../../assets/images/youjiantou.png">
                    </div>
                    <div id="housePedestalPanel" class="part-inputpart-row" @click="houseTypeCode=='2'||resourceStatus=='3'||houseTypeCode=='1' ? '' : clickHousePedestalShow()">
                        <span :class=" 0 == Object.keys(addressInfo.housePedestal).length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">栋座</span>
                        <span class="content-divide">|</span>
                        <span  class="part-inputpart-row-graytext"
                               :class="{partInputpartRowNormaltext :Object.keys(addressInfo.housePedestal).length!=0,partInputpartRowGraytext : this.houseTypeCode==2||this.resourceStatus==3||this.houseTypeCode==1 } ">
                            {{ 0 == Object.keys(addressInfo.housePedestal).length ? '请选择' : addressInfo.housePedestal.estatePedestalName}}
                        </span>
                        <img class="part-inputpart-row-right" :class=" isHousePedestalShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
<!--                    <div>-->
<!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isHousePedestalShow">-->
<!--                            <div class="part-inputpart-dropDownList-option" :class="item==addressInfo.housePedestal ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in housePedestalArr" @click="selectHousePedestalOption(item)" :key="item.id">{{item.estatePedestalName}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div id="unitPanel" class="part-inputpart-row" @click="houseTypeCode=='2'||resourceStatus=='3'||houseTypeCode=='1' ? '' : clickUnitShow()">
                        <span :class=" 0 == Object.keys(addressInfo.unit).length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">单元</span>
                        <span class="content-divide">|</span>
                        <span  class="part-inputpart-row-graytext"
                               :class="{partInputpartRowNormaltext : Object.keys(addressInfo.unit).length !=0,partInputpartRowGraytext : this.houseTypeCode==2||this.resourceStatus==3||this.houseTypeCode==1} ">
                            {{ 0 == Object.keys(addressInfo.unit).length ? '请选择' : addressInfo.unit.estateUnitName}}
                        </span>
                        <img class="part-inputpart-row-right" :class=" isUnitShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
<!--                    <div>-->
<!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isUnitShow">-->
<!--                            <div class="part-inputpart-dropDownList-option" :class="item==addressInfo.unit ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in unitArr" @click="selectUnitOption(item)" :key="item.id">{{item.estateUnitName}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <!--                    门牌号 clickHouseNumberShow part-inputpart-row-graytext 0 == Object.keys(addressInfo.houseNumber).length? '':'partInputpartRowNormaltext'-->
                    <div id="houseNumberPanel" class="part-inputpart-row" @click="houseTypeCode=='2'||resourceStatus=='3'||houseTypeCode=='1' ? '' : clickHouseNumberShow()">
                        <span :class=" 0 == Object.keys(addressInfo.houseNumber).length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">门牌号</span>
                        <span class="content-divide">|</span>
                        <span  class="part-inputpart-row-graytext"
                               :class="{ partInputpartRowNormaltext : Object.keys(addressInfo.houseNumber).length!=0,partInputpartRowGraytext : this.houseTypeCode==2||this.resourceStatus==3||this.houseTypeCode==1 }">
                            {{ 0 == Object.keys(addressInfo.houseNumber).length ? '请选择' : addressInfo.houseNumber.title}}
                        </span>
                        <img class="part-inputpart-row-right" :class=" isHouseNumberShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
<!--                    <div>-->
<!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isHouseNumberShow">-->
<!--                            <div class="part-inputpart-dropDownList-option" :class="item==addressInfo.houseNumber ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in houseNumberArr" @click="selectHouseNumberOption(item)" :key="item.id">{{item.title}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div v-if="houseTypeCode != 2&&houseTypeCode != 1">
                        <a class="part-hyperlinks" @click="manualAddHouseClick"><span>未找到房源？手动输入</span></a>
                    </div>
                </div>
                <div class="part-inputpart" v-else>
                    <div class="part-inputpart-textarea">
                        <div class="part-inputpart-row">
                            <span :class=" 0==address.toString().trim().length ? 'content-none' : 'content-have' "></span>
                            <span class="part-inputpart-row-header">详细地址</span>
                        </div>
                        <textarea v-model="address" placeholder="请输入详细地址" :disabled="this.houseTypeCode==1||this.houseTypeCode==2"></textarea>
                        <div class="part-inputpart-row part-inputpart-row-button">
                            <span class="part-inputpart-row-graytext"></span>
                            <div class="part-inputpart-row-right" @click="addressBind">
                                <div class="part-button part-row-button">绑定楼盘字典</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            房屋信息-->
            <div class="part">
                <span class="part-subtitle">房屋信息</span>
                <div class="part-inputpart">
                    <div class="part-inputpart-row">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">建筑信息</span>
                        <span class="content-divide">|</span>
<!--                        <span class="part-inputpart-row-graytext"></span>-->
                        <div class="part-inputpart-row-right part-inputpart-row-housetype">
                            <span>第</span>
                            <input  :class="{ partInputpartRowGraytext : disabled }"   :disabled=" disabled " type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="2" v-model="houseInfo.buildingInformation.storey" placeholder="0"  />
                            <span>层 共</span>
                            <input :class="{ partInputpartRowGraytext : disabled  }"   :disabled="disabled" type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="2" v-model="houseInfo.buildingInformation.elevator" placeholder="0" />
                            <span>层 每层</span>
                            <input :class="{ partInputpartRowGraytext : disabled  }"   :disabled="disabled" type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="2" v-model="houseInfo.buildingInformation.household" placeholder="0" />
                            <span>户</span>
                        </div>
                    </div>
                    <div class="part-inputpart-row">
                        <div :class=" 0!=houseInfo.houseType.bedroomCount.trim().length ? 'content-have' : 'content-none' "></div>
                        <span >户型</span>
                        <span class="content-divide">|</span>
                        <div class="part-inputpart-row-right part-inputpart-row-housetype">
                            <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="1" v-model="houseInfo.houseType.bedroomCount" placeholder="0"/>
                            <span>室</span>
                            <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="1" v-model="houseInfo.houseType.livingRoomNum" placeholder="0"/>
                            <span>厅</span>
                            <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="1" v-model="houseInfo.houseType.kitchenNum" placeholder="0"/>
                            <span>厨</span>
                            <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="1" v-model="houseInfo.houseType.bathroomNum" placeholder="0"/>
                            <span>卫</span>
                            <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="1" v-model="houseInfo.houseType.balconyNum" placeholder="0"/>
                            <span>阳台</span>
                        </div>
                    </div>
                    <div class="part-inputpart-row">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">租金</span>
                        <span class="content-divide">|</span>
                        <input type="tel" v-model="houseInfo.rent" placeholder="输入金额">
                        <span class="part-inputpart-row-right">元/月</span>
                    </div>
                    <div class="part-inputpart-row">
                        <span :class=" 0==houseInfo.area.toString().trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">面积</span>
                        <span class="content-divide">|</span>
                        <input type="tel" v-model="houseInfo.area" placeholder="输入面积">
                        <span class="part-inputpart-row-right">m²</span>
                    </div>
                    <div id="potentialHousingTypePanel" class="part-inputpart-row" @click="isPotentialHousingTypeShow = !isPotentialHousingTypeShow">
                        <span class="content-have"></span>
                        <span class="part-inputpart-row-header">潜房类型</span>
                        <span class="content-divide">|</span>
                        <span class="partInputpartRowNormaltext">{{0 == Object.keys(houseInfo.potentialHousingType).length ? '私' : houseInfo.potentialHousingType.dictionaryTitle}}盘</span>
                        <img class="part-inputpart-row-right" :class=" isPotentialHousingTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
<!--                    <div>-->
<!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isPotentialHousingTypeShow">-->
<!--                            <div class="part-inputpart-dropDownList-option" :class="item==houseInfo.potentialHousingType ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in potentialHousingTypeArr" @click="selectPotentialHousingTypeOption(item)">{{item.dictionaryTitle}}盘</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div id="expectedDatePanel" class="part-inputpart-row" @click="clickExpectedDateShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header-date">预计交房日期</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext" :class="0==houseInfo.expectedDate ? '': 'part-inputpart-row-redtext' ">{{houseInfo.expectedDate | formatDateTime}}</span>
                        <img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
                    </div>
                    <div id="rentalTypePanel" class="part-inputpart-row" @click="isRentalTypeShow = !isRentalTypeShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">出租类型</span>
                        <span class="content-divide">|</span>
                        <span :class="  houseInfo.rentalType.dictionaryTitle ?  'partInputpartRowNormaltext'  :'part-inputpart-row-graytext' ">
                            {{houseInfo.rentalType.dictionaryTitle ? houseInfo.rentalType.dictionaryTitle : '请选择' }}</span>
                        <img class="part-inputpart-row-right" :class=" isRentalTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
                    <div  class="part-inputpart-row" @click="isDecorationDegreeShow = !isDecorationDegreeShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">装修程度</span>
                        <span class="content-divide">|</span>
                        <span :class="  houseInfo.decorationDegree.dictionaryTitle ?  'partInputpartRowNormaltext'  :'part-inputpart-row-graytext' ">
                            {{houseInfo.decorationDegree.dictionaryTitle ? houseInfo.decorationDegree.dictionaryTitle : '请选择' }}</span>
                        <img class="part-inputpart-row-right" :class=" isDecorationDegreeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
                    <div  class="part-inputpart-row" @click="isSeeHouseShow = !isSeeHouseShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">看房方式</span>
                        <span class="content-divide">|</span>
                        <span :class="  houseInfo.seeHouse.dictionaryTitle ?  'partInputpartRowNormaltext'  :'part-inputpart-row-graytext' ">
                            {{houseInfo.seeHouse.dictionaryTitle ? houseInfo.seeHouse.dictionaryTitle : '请选择' }}</span>
                        <img class="part-inputpart-row-right" :class=" isSeeHouseShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
                    <div  class="part-inputpart-row" @click="isMinRentTimeShow = !isMinRentTimeShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">最短租期</span>
                        <span class="content-divide">|</span>
                        <span :class="  houseInfo.minRentTime.dictionaryTitle ?  'partInputpartRowNormaltext'  :'part-inputpart-row-graytext' ">
                            {{houseInfo.minRentTime.dictionaryTitle ? houseInfo.minRentTime.dictionaryTitle : '请选择' }}</span>
                        <img class="part-inputpart-row-right" :class=" isMinRentTimeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
                    <div  class="part-inputpart-row" @click="isPayTypeShow = !isPayTypeShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">付款方式</span>
                        <span class="content-divide">|</span>
                        <span :class="  houseInfo.payType.dictionaryTitle ?  'partInputpartRowNormaltext'  :'part-inputpart-row-graytext' ">
                            {{houseInfo.payType.dictionaryTitle ? houseInfo.payType.dictionaryTitle : '请选择' }}</span>
                        <img class="part-inputpart-row-right" :class=" isPayTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
                    <div  class="part-inputpart-row" @click="isOrientationShow = !isOrientationShow">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">朝向</span>
                        <span class="content-divide">|</span>
                        <span :class="  houseInfo.orientation.dictionaryTitle ?  'partInputpartRowNormaltext'  :'part-inputpart-row-graytext' ">
                            {{houseInfo.orientation.dictionaryTitle ? houseInfo.orientation.dictionaryTitle : '请选择' }}</span>
                        <img class="part-inputpart-row-right" :class=" isOrientationShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
<!--                    <div>-->
<!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isRentalTypeShow">-->
<!--                            <div class="part-inputpart-dropDownList-option" :class="item==houseInfo.rentalType ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in rentalTypeArr" @click="selectRentalTypeOption(item)">{{item.dictionaryTitle}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="part-inputpart-textarea">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">房屋描述</span>
                        <textarea v-model="houseInfo.describe" placeholder="房屋描述" :class="true ? 'textarea-readonly' : ''"></textarea>
                    </div>
                </div>
            </div>
            <div @click="saveData" class="part part-button" :class=" (!isAddressHave && 0==address.trim().length) ||
                                                   (isAddressHave && ( null==addressInfo.community || 0 == Object.keys(addressInfo.housePedestal).length || 0 == Object.keys(addressInfo.unit).length || 0 == Object.keys(addressInfo.houseNumber).length )) ||
                                                   0 == Object.keys(houseInfo.source).length || 0==ownerInfo.name.trim().length || 0==ownerInfo.phone.trim().length || 0==houseInfo.houseType.bedroomCount.trim().length || 0==houseInfo.area.toString().trim().length || 0 == Object.keys(houseInfo.potentialHousingType).length ? '' : 'part-button-enabled' ">保存</div>
        </div>
<!--        来源弹窗-->
      <van-popup v-model="isSourceShow" position="bottom">
        <van-picker show-toolbar :columns="sourceArr" @cancel="isSourceShow = false" :default-index="sourceIndex" value-key="dictionaryTitle" @confirm="selectSourceOption"/>
      </van-popup>
<!--        潜房类型-->
      <van-popup v-model="isPotentialHousingTypeShow" position="bottom">
        <van-picker show-toolbar :columns="potentialHousingTypeArr" @cancel="isPotentialHousingTypeShow = false" :default-index="potentialHousingTypeIndex" value-key="dictionaryTitle" @confirm="selectPotentialHousingTypeOption"/>
      </van-popup>
<!--        出租类型-->
      <van-popup v-model="isRentalTypeShow" position="bottom">
        <van-picker show-toolbar :columns="rentalTypeArr" @cancel="isRentalTypeShow = false" :default-index="rentalTypeIndex" value-key="dictionaryTitle" @confirm="selectRentalTypeOption"/>
      </van-popup>
<!--        楼栋-->
      <van-popup v-model="isHousePedestalShow" position="bottom">
        <van-picker show-toolbar :columns="housePedestalArr" @cancel="isHousePedestalShow = false" :default-index="housePedestalIndex" value-key="estatePedestalName" @confirm="selectHousePedestalOption"/>
      </van-popup>
<!--      单元  -->
      <van-popup v-model="isUnitShow" position="bottom">
        <van-picker show-toolbar :columns="unitArr" @cancel="isUnitShow = false" :default-index="unitIndex" value-key="estateUnitName" @confirm="selectUnitOption"/>
      </van-popup>
<!--        门牌号-->
      <van-popup v-model="isHouseNumberShow" position="bottom">
        <van-picker show-toolbar :columns="houseNumberArr" @cancel="isHouseNumberShow = false" :default-index="houseNumberIndex" value-key="title" @confirm="selectHouseNumberOption"/>
      </van-popup>
<!--      装修程度的弹窗  -->
      <van-popup v-model="isDecorationDegreeShow" position="bottom">
        <van-picker show-toolbar :columns="decorationDegreeList" @cancel="isDecorationDegreeShow = false" :default-index="decorationDegreeIndex" value-key="dictionaryTitle" @confirm="selectDecorationDegreeOption"/>
      </van-popup>
    <!--      看房方式 的弹窗  -->
    <van-popup v-model="isSeeHouseShow" position="bottom">
        <van-picker show-toolbar :columns="seeHouseList" @cancel="isSeeHouseShow = false" :default-index="seeHouseIndex" value-key="dictionaryTitle" @confirm="selectSeeHouseOption"/>
    </van-popup>
    <!--      最短租期的弹窗  -->
    <van-popup v-model="isMinRentTimeShow" position="bottom">
        <van-picker show-toolbar :columns="minRentTimeList" @cancel="isMinRentTimeShow = false" :default-index="minRentTimeIndex" value-key="dictionaryTitle" @confirm="selectMinRentTimeOption"/>
    </van-popup>
    <!--      付款方式的弹窗  -->
    <van-popup v-model="isPayTypeShow" position="bottom">
        <van-picker show-toolbar :columns="payTypeList" @cancel="isPayTypeShow = false" :default-index="payTypeIndex" value-key="dictionaryTitle" @confirm="selectPayTypeOption"/>
    </van-popup>
    <!--      朝向的弹窗  -->
    <van-popup v-model="isOrientationShow" position="bottom">
        <van-picker show-toolbar :columns="orientationList" @cancel="isOrientationShow = false" :default-index="orientationIndex" value-key="dictionaryTitle" @confirm="selectOrientationOption"/>
    </van-popup>
<!--        预计交房日期-->
    <van-popup v-model="isExpectedDateShow" position="bottom">
        <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" item-height="35px" @confirm="expectedDateConfirm" @cancel="expectedDateCancel"/>
    </van-popup>
    </div>
</template>

<script>

import {
  NavBar,
  DatetimePicker,
  Toast, Picker, Popup
} from 'vant'
    import {
        addOrUpdatePotentialHouse,
        queryBaseData, queryEstatePedestalList, queryEstateRoomDetailList, queryEstateUnitList, queryPotentialHouse,
    } from "../../../getData/getData";
    import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus.js"


    export default {

        name : 'RegisterPotentialHouse',

        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Toast.name]: Toast,
          [Picker.name]: Picker,
          [Popup.name]: Popup
        },

        data() {
            return {
                decorationDegreeIndex : '',
                seeHouseIndex : '',
                minRentTimeIndex : '',
                payTypeIndex : '',
                orientationIndex : '',
                disabled : true,
              sourceIndex: '',
              potentialHousingTypeIndex: '',
              rentalTypeIndex: '',
              housePedestalIndex: '',
              unitIndex: '',
              houseNumberIndex: '',
                //申请状态
                houseTypeCode : '',
                //潜房状态
                resourceStatus : '',
                //从哪个页面来的
                fromName: '',
                //从其他页面获取了几次数据
                otherDataCount: '',
                // 地址
                isAddressHave: true,
                //栋座
                isHousePedestalShow: false,
                // 单元
                isUnitShow: false,
                //门牌号
                isHouseNumberShow: false,
                // 来源
                isSourceShow: false,
                // 潜房类型
                isPotentialHousingTypeShow: false,
                // 日期
                isExpectedDateShow: false,
                // 租金
                isRentalTypeShow: false,
                //日期
                currentDate: new Date(),
                //潜在资源id
                id: '',
                //潜房id
                prId: '',
                // //员工id
                // staff_id : '',
                //公盘需要
                idList: '',
                ownerInfo: {
                    name: '',
                    phone: '',
                    recommender: this.$route.params.referrerName || '',
                    recommenderId: this.$route.params.referrerId || '',
                },
                //完整地址
                address: '',
                addressInfo: {
                    community:  '',
                    communityId: '',
                    housePedestal: {},
                    unit: {},
                    houseNumber: {}
                },
                houseInfo: {
                    buildingInformation:{
                        //第几层
                        storey: '',
                        //一共几层
                        elevator: '',
                        //每层几户
                        household: ''
                    },
                    houseType: {
                        bedroomCount: '',
                        livingRoomNum: '',
                        kitchenNum: '',
                        bathroomNum: '',
                        balconyNum: ''
                    },
                    rent: '',
                    source: {},
                    area: '',
                    potentialHousingType: {},
                    potentialHousingTypeLaborer:{},
                    expectedDate: '',
                    rentalType: {},
                    decorationDegree: {},
                    seeHouse: {},
                    orientation: {},
                    minRentTime: {},
                    payType: {},
                    describe: ''
                },
                housePedestalArr: [],
                unitArr: [],
                houseNumberArr: [],
                sourceArr: [],
                potentialHousingTypeArr: [],
                rentalTypeArr: [],
                decorationDegreeList: [], // 装修程度列表
                isDecorationDegreeShow : false,
                seeHouseList: [], // 看房方式列表
                isSeeHouseShow : false,
                orientationList: [], // 朝向列表
                isOrientationShow : false,
                minRentTimeList: [], // 租期列表
                isMinRentTimeShow : false,
                payTypeList: [], // 付款方式列表
                isPayTypeShow : false,
            }
        },

        //监听数值变化
        watch: {

            // '$route' (to, from) {
            //     // from 对象中要 router 来源信息.
            //     // do your want
            //     console.log(from.name)
            //     this.fromName = from.name
            // },

            'houseInfo.rent': function (newVal,oldVal) {
                var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g;
                if(!reg.test(newVal)){
                    if(newVal == ''){
                        this.houseInfo.rent = '';
                        return;
                    }
                    this.houseInfo.rent = oldVal
                }else{
                    this.houseInfo.rent = newVal;
                }
            },
            'houseInfo.area': function (newVal,oldVal) {
                var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g;
                if(!reg.test(newVal)){
                    if(newVal == ''){
                        this.houseInfo.area = '';
                        return;
                    }
                    this.houseInfo.area = oldVal
                }else{
                    this.houseInfo.area = newVal;
                }
            },

        },

        //判断条件 暂不确定是否有效
        // beforeRouteLeave (to, from, next) {
        //     if (to.name === 'chooseCommunity' || to.name === 'referrer') {
        //         from.meta.keepAlive = true
        //     } else {
        //         from.meta.keepAlive = false
        //     }
        //     next()
        // },
        beforeRouteLeave:function(to, from, next){
            if (from && to.name != 'chooseCommunity' && to.name != 'referrer')
            {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
                if (this.$vnode && this.$vnode.data.keepAlive)
                {
                    if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
                    {
                        if (this.$vnode.componentOptions)
                        {
                            var key = this.$vnode.key == null
                                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                                : this.$vnode.key;
                            var cache = this.$vnode.parent.componentInstance.cache;
                            var keys  = this.$vnode.parent.componentInstance.keys;
                            if (cache[key])
                            {
                                if (keys.length) {
                                    var index = keys.indexOf(key);
                                    if (index > -1) {
                                        keys.splice(index, 1);
                                    }
                                }
                                delete cache[key];
                            }
                        }
                    }
                }
                this.$destroy();
            }
            next();
        },
        //使用keep-alive后刷新数据
        activated() {
            eventBus.$on('chooseCommunity', function(data){
                    if(data.searchInfo && data.searchId){
                        // if(0!=data.searchInfo.length && 0!=data.searchId.length)
                        //     this.isCommunitySelected = true
                        if(data.searchInfo != this.addressInfo.community || this.addressInfo.communityId != data.searchId){
                            this.addressInfo.housePedestal = ''
                            this.addressInfo.unit = ''
                            this.addressInfo.houseNumber = ''
                        }
                            //赋值小区数据
                            this.addressInfo.community = data.searchInfo
                            this.addressInfo.communityId = data.searchId
                    }

            }.bind(this));
            eventBus.$on('referrer', function(data){
                debugger
                if(data.mobile && data.referrerId){
                        if(data.referrerName != undefined){
                            this.ownerInfo.recommender = data.referrerName
                        }else{
                            this.ownerInfo.recommender = data.mobile
                        }

                        this.ownerInfo.recommenderId = data.referrerId
                }
            }.bind(this));
        },
        created() {
            //更换导航头文本
            if(this.$route.query.addHouse!=undefined)
                this.fromName=this.$route.query.addHouse
            //路由里传的是erId 潜房id
            if(this.$route.query.prId)
                this.prId = this.$route.query.prId
            if(!this.prId) return
        },
        mounted() {
            this.initDicData()
            if(this.prId!=''){
                this.initData()
            }
            //console.log(this.$vnode)
            // console.log(this.$vnode.parent)
            // console.log(this.$vnode.parent.componentInstance)
            //console.log(this.$vnode.parent.componentInstance.cache)
        },

        filters: {
            formatDateTime(value) {
                if(null == value || ''==value) return
                let date = new Date(value);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                let h = date.getHours();
                h = h < 10 ? "0" + h : h;
                let m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
                let s = date.getSeconds();
                s = s < 10 ? "0" + s : s;
                // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
                return y+'-'+MM+'-'+d
            }
        },

        methods: {
            // changeBackgroundColor(){
            //    var a =  document.getElementsByName("changeColor");
            //         a.style.color="red";
            //    },

            checkPhone() {
                var reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
                if('' != this.ownerInfo.phone){
                    if(!reg.test(this.ownerInfo.phone)){
                        Toast('手机号格式错误')
                        this.ownerInfo.phone = ''
                    }
                }
            },

            //初始化页面数据
            initData:function(){
                const that = this
                let initData = {}
                initData.id = that.prId
                initData.staff_id = getStaffId()
                //if(null==initData.staff_id || null==initData.id || '' == initData.staff_id || '' == initData.id) return
                queryPotentialHouse(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let data = response.data.data
                        console.log(data)
                        //id
                        that.id = data.id
                        if(data.idList)
                            that.idList = data.idList
                        //添加时间
                        if(data.addTime)
                           that.currentDate = new Date(data.addTime)
                        //客户姓名
                        if(data.userName)
                           that.ownerInfo.name = data.userName
                        //联系电话
                        if(data.mobile)
                           that.ownerInfo.phone = data.mobile
                        //来源
                        that.houseInfo.source.id = data.potentialHouseSource_id
                        that.houseInfo.source.dictionaryTitle = data.potentialHouseSourceName
                        //卧室
                        if(data.bedroomCount)
                           that.houseInfo.houseType.bedroomCount = data.bedroomCount
                        //面积
                        if(data.builtUpArea)
                           that.houseInfo.area = data.builtUpArea
                        //潜房类型
                        if(data.resourceBelog){
                            that.houseInfo.potentialHousingType.dictionaryValue = data.resourceBelog
                            for (var x = 0; x < that.potentialHousingTypeArr.length; x++)
                                if (that.potentialHousingTypeArr[x].dictionaryValue == data.resourceBelog)
                                    that.houseInfo.potentialHousingType.dictionaryTitle = that.potentialHousingTypeArr[x].dictionaryTitle
                        }

                        //小区
                        if(data.estateName&&data.estateRoom_id)
                           that.addressInfo.community = data.estateName
                        if(data.estate_id)
                           that.addressInfo.communityId = data.estate_id
                        //行政街
                        if(data.districts)
                           that.addressInfo.housePedestal.districts = data.districts
                        //行政号
                        if(data.districtsCode)
                           that.addressInfo.housePedestal.districtsCode = data.districtsCode
                        //楼盘栋座id
                        if(data.estatePedestal_id)
                           that.addressInfo.housePedestal.epId = data.estatePedestal_id
                        if(data.pedestalName)
                          that.addressInfo.housePedestal.estatePedestalName = data.pedestalName
                        //楼盘单元id
                        if(data.estateUnit_id)
                          that.addressInfo.unit.euId = data.estateUnit_id
                        if(data.estateUnitName)
                          that.addressInfo.unit.estateUnitName = data.estateUnitName+"单元"
                        if(data.estateRoom_id){
                            that.addressInfo.houseNumber.erId = data.estateRoom_id
                        }else{
                            that.isAddressHave = false
                            that.disabled = false
                        }
                        //门牌号
                        // if(!data.estateRoom_id||!data.houseTypeCode==2){
                        //     that.addressInfo.houseNumber.erId = data.estateRoom_id
                        //     that.isAddressHave = false
                        //     that.disabled = false
                        // }

                        if(data.roomName)
                          that.addressInfo.houseNumber.title = data.roomName
                        //完整地址
                        if(data.address)
                          that.address = data.address
                        //第几层
                        if(data.floor)
                            that.houseInfo.buildingInformation.storey = data.floor
                        //每层几户
                        if(data.userCountEachLevel)
                            that.houseInfo.buildingInformation.household = data.userCountEachLevel
                        //共几层
                        if(data.totalFloor)
                            that.houseInfo.buildingInformation.elevator = data.totalFloor

                        //厅
                        if(data.livingroomCount)
                           that.houseInfo.houseType.livingRoomNum = data.livingroomCount
                        //厨房
                        if(data.kitchenCount)
                           that.houseInfo.houseType.kitchenNum = data.kitchenCount
                        //卫
                        if(data.bathroomCount)
                           that.houseInfo.houseType.bathroomNum = data.bathroomCount
                        //阳台
                        if(data.balconyCount)
                           that.houseInfo.houseType.balconyNum = data.balconyCount

                        //推荐人id
                        that.ownerInfo.recommenderId = data.recommendUser_id
                        that.ownerInfo.recommender = data.recommendUserName
                        //租金
                        if(data.rentMoney)
                           that.houseInfo.rent = data.rentMoney
                        //交房时间
                        if(data.houseExpectedTime)
                                that.houseInfo.expectedDate = new Date(data.houseExpectedTime)
                        //出租类型
                        that.houseInfo.rentalType.id = data.rentType_id
                        that.houseInfo.rentalType.dictionaryTitle = data.rentTypeName
                        //房屋描述
                        that.houseInfo.describe = data.remarks
                        //申请状态
                        that.houseTypeCode=data.houseTypeCode
                        that.resourceStatus = data.resourceStatus
                        //更换文本背景色
                        //装修程度
                        that.houseInfo.decorationDegree.id = data.decorationDegree_id
                        that.houseInfo.decorationDegree.dictionaryTitle = data.decorationDegreeName
                        //看房方式
                        that.houseInfo.seeHouse.id = data.seeHouse_id
                        that.houseInfo.seeHouse.dictionaryTitle = data.seeHouseName
                        //最短租期
                        that.houseInfo.minRentTime.id = data.minRentTime_id
                        that.houseInfo.minRentTime.dictionaryTitle = data.minRentTimeName
                        //付款方式
                        that.houseInfo.payType.dictionaryValue = data.payType_id
                        that.houseInfo.payType.dictionaryTitle = data.payTypeName
                        //朝向
                        that.houseInfo.orientation.id = data.orientation_id
                        that.houseInfo.orientation.dictionaryTitle = data.orientationName
                    })
                })
            },

            //初始化字典数据
            initDicData:function(){
                const that = this
                let initData = {}
                initData.dbName = ['description','potentialHouseSource','rentType','decorationDegree','seeHouse','houseOrientation','minRentTime']
                initData.fdName = ['RESOURCEBELOGMAP','CYCLETYPEMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data)
                        //潜房类型
                        that.potentialHousingTypeArr = response.data.data.RESOURCEBELOGMAP
                        if(!that.$route.query.prId){
                            that.houseInfo.potentialHousingType = response.data.data.RESOURCEBELOGMAP[0]
                        }
                        //出租类型
                        that.rentalTypeArr = response.data.data.rentType
                        //来源类型
                        that.sourceArr = response.data.data.potentialHouseSource
                        //装修程度
                        that.decorationDegreeList = response.data.data.decorationDegree
                        //看房方式
                        that.seeHouseList = response.data.data.seeHouse
                        //朝向
                        that.orientationList = response.data.data.houseOrientation
                        //最短租期
                        that.minRentTimeList = response.data.data.minRentTime
                        //付款方式
                        that.payTypeList = response.data.data.CYCLETYPEMAP
                    })
                })
            },

            // 保存数据
            saveData: function () {
                var that = this
                let initData = {}
                if(0 == Object.keys(that.houseInfo.source).length || 0==that.ownerInfo.name.trim().length || 0==that.ownerInfo.phone.trim().length || 0==that.houseInfo.houseType.bedroomCount.trim().length || 0==that.houseInfo.area.toString().trim().length || 0 == Object.keys(that.houseInfo.potentialHousingType).length) return
                //别处获取的
                initData.addStaff_id = getStaffId()
                initData.staff_id = getStaffId()
                //潜在资源id
                if(''!=that.id)
                    initData.id = that.id
                //公盘需要
                if(''!=that.idList)
                    initData.idList = that.idList

                //本表单
                initData.addTime = that.currentDate
                //客户姓名
                initData.ownerName = that.ownerInfo.name
                //联系电话
                initData.mobile = that.ownerInfo.phone
                //来源
                initData.potentialHouseSource_id = that.houseInfo.source.id
                //卧室
                initData.bedroomCount = that.houseInfo.houseType.bedroomCount
                //面积
                initData.builtUpArea = that.houseInfo.area
                //潜房类型
                if(that.houseInfo.potentialHousingType.dictionaryValue)
                    initData.resourceBelog = that.houseInfo.potentialHousingType.dictionaryValue
                else
                    initData.resourceBelog = that.potentialHousingTypeArr[0].dictionaryValue


                if(that.isAddressHave){
                    // if(null==that.addressInfo.community || 0 == Object.keys(that.addressInfo.housePedestal).length || 0 == Object.keys(that.addressInfo.unit).length || 0 == Object.keys(that.addressInfo.houseNumber).length) return;
                    //房间id
                    initData.estateRoom_id = that.addressInfo.houseNumber.erId
                    //行政街
                    initData.districts = that.addressInfo.housePedestal.districts
                    //行政号
                    initData.districtsCode = that.addressInfo.housePedestal.districtsCode
                    //楼盘栋座id
                    initData.housePedestal = that.addressInfo.housePedestal.epId
                    //楼盘单元id
                    initData.houseUnit = that.addressInfo.unit.euId
                    //门牌号
                    initData.houseNumberPlate = that.addressInfo.houseNumber.title
                }else {
                    if(0==that.address.trim().length) return;
                    //完整地址
                    initData.wholeAddress = that.address
                }

                //第几层
                initData.floor = that.houseInfo.buildingInformation.storey
                //每层几户
                initData.userCountEachLevel = that.houseInfo.buildingInformation.household
                //共几层
                initData.totalFloor = that.houseInfo.buildingInformation.elevator

                //厅
                initData.livingroomCount = that.houseInfo.houseType.livingRoomNum
                //厨房
                initData.kitchenCount = that.houseInfo.houseType.kitchenNum
                //卫
                initData.bathroomCount = that.houseInfo.houseType.bathroomNum
                //阳台
                initData.balconyCount = that.houseInfo.houseType.balconyNum

                //推荐人id
                initData.recommendUser_id = that.ownerInfo.recommenderId
                //租金
                initData.rentMoney = that.houseInfo.rent
                //交房时间
                initData.houseExpectedTime = that.houseInfo.expectedDate
                //出租类型
                initData.rentType_id = that.houseInfo.rentalType.id
                //装修程度
                initData.decorationDegree_id = that.houseInfo.decorationDegree.id
                //看房方式
                initData.seeHouse_id = that.houseInfo.seeHouse.id
                //最短租期
                initData.minRentTime_id = that.houseInfo.minRentTime.id
                //朝向
                initData.orientation_id = that.houseInfo.orientation.id
                //付款方式
                initData.payType_id = that.houseInfo.payType.dictionaryValue
                //房屋描述
                initData.remarks = that.houseInfo.describe
                addOrUpdatePotentialHouse(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        //console.log(response)
                        responseUtil.alertMsg(that,response.data.msg)
                        that.leftReturn();
                    })
                })
            },

            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            //点击下拉框区域外 下拉框隐藏
            clickRecommenderShow() {
                this.$router.push({
                    name:'referrer',
                    query:{
                        type:'5',
                    }
                });
            },
            clickCommunityShow() {
                this.$router.push({
                    name:'chooseCommunity',
                    params:{
                    }
                    // path: '../view/dt/chooseCommunity',
                    // query: {}
                });
            },
            clickHousePedestalShow() {
                let that = this
                if(that.addressInfo.community){
                    that.isHousePedestalShow = !that.isHousePedestalShow;
                    let initData_queryEstatePedestalList = {
                        estate_id : that.addressInfo.communityId
                    }
                    queryEstatePedestalList(initData_queryEstatePedestalList).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            that.housePedestalArr = response.data.data.roomHouseList
                        })
                    })
                }
            },
            selectHousePedestalOption(item,index){
                this.addressInfo.housePedestal = item
              this.housePedestalIndex = index
                this.isHousePedestalShow = false
            },
            clickUnitShow() {
                let that = this
                if(that.addressInfo.housePedestal){
                    that.isUnitShow = !that.isUnitShow;
                    let initData_housePedestal = {
                        estatePedestal_id : that.addressInfo.housePedestal.epId
                    }
                    queryEstateUnitList(initData_housePedestal).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            that.unitArr = response.data.data.roomHouseList
                        })
                    })
                }
            },
            selectUnitOption(item,index){
                this.addressInfo.unit = item
                this.houseInfo.buildingInformation.elevator = item.levelCount
                this.houseInfo.buildingInformation.household = item.userCountEachLevel
              this.unitIndex = index
                this.isUnitShow = false
            },
            clickHouseNumberShow() {
                let that = this
                if(that.addressInfo.unit){
                    that.isHouseNumberShow = !that.isHouseNumberShow;
                    let initData= {
                        estateUnit_id : that.addressInfo.unit.euId
                    }
                    queryEstateRoomDetailList(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            that.houseNumberArr = response.data.data.roomHouseList
                        })
                    })
                }
            },
            selectHouseNumberOption(item,index){
                this.addressInfo.houseNumber = item
                this.houseInfo.buildingInformation.storey = item.floor
              this.houseNumberIndex = index
                this.isHouseNumberShow = false
            },
            clickSourceShow() {
                this.isSourceShow = !this.isSourceShow;
                var panel = document.getElementById('sourcePanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isSourceShow = false
                        }
                    })
                }
            },
            selectSourceOption(item,index){
                this.houseInfo.source = item
              this.sourceIndex = index
                this.isSourceShow = false
            },
            clickPotentialHousingTypeShow() {
                this.isPotentialHousingTypeShow = !this.isPotentialHousingTypeShow;
            },
            selectPotentialHousingTypeOption(item,index){
                this.houseInfo.potentialHousingType = item
              this.potentialHousingTypeIndex = index
                this.isPotentialHousingTypeShow = false
            },
            clickExpectedDateShow() {
                this.isExpectedDateShow = !this.isExpectedDateShow;
            },
            clickRentalTypeShow() {
                this.isRentalTypeShow = !this.isRentalTypeShow;
            },
            selectRentalTypeOption(item,index){
                this.houseInfo.rentalType = item
                this.rentalTypeIndex = index
                this.isRentalTypeShow = false
            },
            selectDecorationDegreeOption(item,index){
                this.houseInfo.decorationDegree = item
                this.decorationDegreeIndex = index
                this.isDecorationDegreeShow = false
            },
            selectSeeHouseOption(item,index){
                this.houseInfo.seeHouse = item
                this.seeHouseIndex = index
                this.isSeeHouseShow = false
            },
            selectMinRentTimeOption(item,index){
                this.houseInfo.minRentTime = item
                this.minRentTimeIndex = index
                this.isMinRentTimeShow = false
            },
            selectPayTypeOption(item,index){
                this.houseInfo.payType = item
                this.payTypeIndex = index
                this.isPayTypeShow = false
            },
            selectOrientationOption(item,index){
                this.houseInfo.orientation = item
                this.orientationIndex = index
                this.isOrientationShow = false
            },
            //日期选择组件 点击确认键后执行方法
            expectedDateConfirm(value){
                 // var date = new Date(value)
                 // this.houseInfo.expectedDate = date
                this.houseInfo.expectedDate = value
                 //this.houseInfo.expectedDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
                 this.isExpectedDateShow = false
            },
            //日期选择组件 点击取消键后执行方法
            expectedDateCancel(){
                this.isExpectedDateShow = false
            },
            addressBind(){
                this.isAddressHave = true
                this.disabled = true
                //第几层
                this.houseInfo.buildingInformation.storey = ''
                //每层几户
                this.houseInfo.buildingInformation.household = ''
                //共几层
                this.houseInfo.buildingInformation.elevator = ''
            },
            //手动输入房源
            manualAddHouseClick(){
                this.disabled = false
                this.isAddressHave = false
              //行政街
              this.addressInfo.housePedestal = {}
              //楼盘单元id
              this.addressInfo.unit = {}
              //门牌号
              this.addressInfo.houseNumber = {}
              //第几层
              this.houseInfo.buildingInformation.storey = ''
              //每层几户
              this.houseInfo.buildingInformation.household = ''
              //共几层
              this.houseInfo.buildingInformation.elevator = ''
              this.addressInfo.community = ''
              this.addressInfo.communityId = ''
            }
        }


    }
</script>

<style scoped>

    .content{
        margin-top: 52px;
    }

    .part {
        /*margin: 15px;*/
        width: 92%;
        margin: 0 auto;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }

    .part-button{
        height: 40px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 900;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .part-button-enabled{
        background-image:linear-gradient(to right,#ffc274, #ff5d3b);
    }

    .part-hyperlinks{
        float: right;
        color: #3891fa;
        font-size: 12px;
        text-decoration: underline;
        margin-top: 10px;
    }

    .part-inputpart div:last-child{
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-header{
        font-weight: 900;
        width: 20%;
    }
    .part-inputpart-row-header-date{
        font-weight: 900;
        /*width: 20%;*/
    }

    .part-inputpart-textarea{
        display: flex;
        flex-direction: column;
        margin-top: 2px;
        background-color: white;
        padding-top: 6px;
    }

    .part-inputpart-textarea textarea{
        height: 60px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder{
        color: #d8d8d8;
    }

    .part-inputpart-row input {
        border: 0;
        width: 60%;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-dropDownList{
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-inputpart-dropDownList-option{
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-inputpart-dropDownList-option-selected{
        color: #ff5d3b;
    }

    .part-inputpart-row-enablenon {
        margin-left: 26px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 60%;
    }
    .partInputpartRowGraytext {
        color: #d8d8d8;
        background-color: #f7f7f7;
        /*width: 100%;*/
        height: 35px;
        line-height: 37px;

    }

    .partInputpartRowNormaltext{
        color: black;
        width: 100%;
    }

    .part-inputpart-row-redtext{
        width: 100%;
        color: #ff5d3b;
    }
    .part-inputpart-row-housetype{
        width: 90%;
        display: flex;
        align-items: center;
    }
    .part-inputpart-row-housetype input{
        width: 23px;
        padding: 0 5px;
        /*margin: 0 5px;*/
        text-align: center;
    }

    .part-inputpart-row-right{
        /*float: right;*/
        margin-right: 10px;
    }

    .part-inputpart-row-right-rightArrow{
        width: 8px;
    }

    .part-inputpart-row-right-downArrow{
        width: 9px;
    }

    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }
    .part-inputpart-row-right-timepick{
        width: 20px;
    }



    /*.part-inputpart-row-right-rightArrow{*/
    /*    width: 10px;*/
    /*    height: 20px;*/
    /*    background: url("../../../assets/images/youjiantou.png");*/
    /*    background-repeat: no-repeat;*/
    /*    background-size: contain;*/
    /*    background-position:center;*/
    /*}*/

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .part-inputpart-row-button{
        height: 60px;
    }

    .part-row-button{
        margin: 0;
        height: 35px;
        width: 100px;
        border-radius: 8px;
        line-height: 35px;
        font-size: 12px;
        background-image:linear-gradient(to right,#ffc274, #ff5d3b);
    }
    .referrerDefaul{
        font-size: 13px;
        color: #c7c7c7;
    }

    .part-placeHolder{
        height: 20px;
        width: 100%;
    }

</style>
